import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@olinfo/react-components/dist/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/algobadge/(categories)/header.module.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/algobadge/(categories)/navbar.tsx\",\"import\":\"Baloo_2\",\"arguments\":[{\"weight\":[\"400\",\"500\"],\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"titleFont\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Fireworks"] */ "/app/src/components/fireworks.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/navbar/dropdown-action.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LoginButton"] */ "/app/src/components/navbar/login-button.tsx");
